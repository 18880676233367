import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import RegistrationFormUI from "./components/registration/form/ui/RegistrationFormUI";
import RegistrationResponseUI from "./components/registration/response/success/ui/RegistrationResponseUI";
import EmailRedirectUI from "./components/supervisor_email_response/ui/EmailRedirectUi";
import UserConfirmationUI from "./components/user_email_response/ui/UserConfirmationUI";
import WLErrorResponse from "./components/registration/response/wl-error/WLErrorResponse";
import Footer from "./components/Footer";
import Disclaimers from "./components/Disclaimers"
import Header from "./components/Header";
import Confirmation from "./components/supervisor_email_response/ui/Confirmation";
import logo from '../src/assets/logo.png'


export default function App() {
  return (
    <Router >
      <div style={{display:'flex',minHeight:'100vh',flexDirection:'column'}}>
        <Header><img src={logo} alt="logo" height="150" /><br/>
        </Header><br/>
        <div style={{flex:1,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Switch>
          <Route exact path="/" >
            <Disclaimers />
          </Route>
          <Route path="/registration-response/success" exact={true}>
            <RegistrationResponseUI />
          </Route>
          <Route path="/registration-response/wl-error" exact={true}>
            <WLErrorResponse />
          </Route>
          <Route path="/confirmation" exact={true}>
            <Confirmation />
          </Route>

          <Route path="/federal/confirm/:slug" >
          <UserConfirmationUI />
          </Route>
          <Route path="/federal/approve/:slug" children={<EmailRedirectUI />}>
          <EmailRedirectUI />
          </Route>
          <Route path="/register">
            <RegistrationFormUI />
          </Route>
        </Switch>
      </div>
      <Footer>
        <span><small>This Web site is funded in part through a grant from the Office of Juvenile Justice and Delinquency Prevention, Office of Justice Programs, U.S. Department of Justice.
             <br/>Neither the U.S. Department of Justice nor any of its components operate, control, are responsible for, or necessarily endorse, this Web site 
              (including, without limitation, <br/> its content, technical infrastructure, and policies, and any services or tools provided).</small></span>
      </Footer>
      </div>
    </Router>
  );
}




