import React from "react";


const footerStyle = {
  backgroundColor: "#0236d1",
  fontSize: "20px",
  color: "white",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "20px",
  width: "100%"
};


export default function Footer({ children }) {
  return (
    <div>
      <div style={footerStyle}>{children}</div>
    </div>
  );
}