import api from "../../../utils/api";
import { useState } from 'react'
import jwt_decode from "jwt-decode";
import emailjs from 'emailjs-com';

const EmailRedirectLogic = () => {
    const [err, setErr] = useState()

    const Approve = async (JWT) => {
        const decodedJWT = jwt_decode(JWT)
        let email = decodedJWT.email

        try {
            const response = await api.patch(`/core/limited/${JWT}/approve/`)
            console.log("Approved")
            return response
        } catch (error) {
            console.error(error)
            setErr(error)
            const templateParams = {
                user: email
            }
            emailjs.send(`gmail`, 'template_57n2pe1', templateParams, 'user_MG7aykG2jeRSo98STRYC9')
        }
    }

    return {err, Approve}
}

export default EmailRedirectLogic