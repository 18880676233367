import React from "react";
import '../styles/UserConfirmationStyles.css'
import UserConfirmationLogic from '../logic/UserConfirmationLogic'
import { useParams } from "react-router-dom";


const UserConfirmationUI = () => {

    const { slug } = useParams();
    UserConfirmationLogic(slug);

    return (
        <div style={{textAlign:'center',marginTop:'30vh'}}>
            <h2>Thank you for confirming your account.</h2>
        </div>
    )
}

export default UserConfirmationUI;