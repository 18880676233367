import React from "react";
import "../styles/RegistrationFormStyles.css";
import { Form, Field } from "react-final-form";
import ReCAPTCHA from "react-google-recaptcha";
import RegistrationFormLogic from "../logic/RegistrationFormLogic";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const RegistrationFormUI = (props) => {

  const { status, disabledBtn, value, ready, setValue, onSubmit, mustBeString, mustBeEmail, mustBePhoneNumber, setDisabledBtn, renderSuggestions, changeAddressDisplayedValueOnInput } = RegistrationFormLogic(props);


  const onChange = async (value) => {
    setDisabledBtn(false)
  }

  return (/* eslint-disable jsx-a11y/accessible-emoji */

    <div class={"wrapper"}>
      <div class={"title"}>ICAC Partners Registration Form</div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine, values }) => (
          <>
            <form onSubmit={handleSubmit} class="form">
              <div class="formInnerContainer">
                <div class="formHalf1">
                  <div>
                    <label>First Name</label>
                    <Field
                      name="first_name"
                      component="input"
                      type="text"
                      validate={mustBeString}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="First Name" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <label>Last Name</label>
                    <Field
                      name="last_name"
                      component="input"
                      type="text"
                      validate={mustBeString}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="Last Name" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <label>Email</label>
                    <Field
                      name="email"
                      component="input"
                      type="text"
                      validate={mustBeEmail}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="Email Address" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <label>Address</label>
                    <Field
                      name="address"
                      component="input"
                      type="text"
                    >
                      {({ input, meta }) => (
                        <div>
                          {/* setValue sets displayed value through combobox, input.onChange sets the actual value of the form field */}
                          <Combobox {...input} onSelect={(e) => { setValue(e); input.onChange(e); }}>
                            <ComboboxInput
                              style={{ width: 300, maxWidth: "90%" }}
                              required
                              placeholder="Your Address"
                              value={value}
                              onChange={changeAddressDisplayedValueOnInput}
                              disabled={!ready}
                            />
                            <ComboboxPopover>
                              <ComboboxList>{status === "OK" && renderSuggestions()}
                                <img
                                  src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
                                  alt="Powered by Google"
                                />
                              </ComboboxList>
                            </ComboboxPopover>
                          </Combobox>
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <label>Office Phone Number</label>
                    <Field
                      name="office_phone"
                      component="input"
                      type="text"
                      placeholder="Your Office #"
                      validate={mustBePhoneNumber}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="Phone Number" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>

                  <div>
                    <label>Department</label>
                    <Field
                      name="department"
                      component="input"
                      type="text"
                      validate={mustBeString}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="The department you represent" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div class="formHalf2">
                  <div>
                    <label>Supervisor Name</label>
                    <Field
                      name="supName"
                      component="input"
                      type="text"
                      placeholder="Supervisor Name"
                      validate={mustBeString}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="Supervisor Name" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <label>Supervisor Email</label>
                    <Field
                      name="supEmail"
                      component="input"
                      type="text"
                      validate={mustBeEmail}
                    >
                      {({ input, meta }) => (
                        <div>
                          <input  {...input} required placeholder="Supervisor Email Address" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    </div>
                    </div>
                    </div>
                    <div class={"center"}>
                    <ReCAPTCHA
                        sitekey="6LeMVCMcAAAAAKlN7aOx-rqYq7hjBN4J8X3Q9BAN"
                        onChange={onChange}
                      />
                      </div>
              <button type="submit" disabled={disabledBtn || submitting}>
                Submit
              </button>
            </form>
          </>
        )}
      />
    </div>
  )
}

export default RegistrationFormUI;
