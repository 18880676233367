import { useState } from "react";
import api from "../../../../utils/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  ComboboxOption,
} from "@reach/combobox";

const RegistrationFormLogic = () => {
  const mustBeString = value => (/^[a-z\d\-_\s]*$/i.test(value) ? undefined : "This field can only contain letters and numbers");
  const mustBeEmail = value => (/\S+@\S+\.\S+/.test(value) ? undefined : "Please enter a valid email");
  const mustBePhoneNumber = value => (/^[0-9]{10}$/g.test(value) ? undefined : "Please enter a valid 10 digit phone number with no dashes");
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [placeID, setPlaceID] = useState();
  

  const onSubmit = async (values) => {
    if (disabledBtn === false) {
      postData(values);
    } else {
      console.error("You dun messed up")
    }

  };

  const postData = async (data) => {
    await api.post(`/core/limited/register/`, {
      first_name: data['first_name'],
      last_name: data['last_name'],
      email: data['email'],
      department: data['department'],
      office_phone: data['office_phone'],
      supervisor_name: data['supName'],
      supervisor_email: data['supEmail'],
      office_address: data['address'],
    })
      // TODO consider getting rid of this before production
      .then(() => {
        window.location.replace('/registration-response/success/')
      })
      .catch(function (error) {
        error.response.status === 403 ? (window.location.replace('/registration-response/wl-error/')) :
          (alert('there has been an error:' + JSON.stringify(error.response.data)))
      });
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const changeAddressDisplayedValueOnInput = (e) => {
    setValue(e.target.value);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        description,
      } = suggestion;

      return (
        <ComboboxOption key={place_id} onClick={setPlaceID(place_id)} value={description} />
      );
    });

  return { placeID, status, disabledBtn, value, ready, setValue, onSubmit, mustBeString, mustBeEmail, mustBePhoneNumber, setDisabledBtn, renderSuggestions, changeAddressDisplayedValueOnInput }

}

export default RegistrationFormLogic;