import '../styles/EmailRedirectStyles.css'
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import EmailRedirectLogic from '../logic/EmailRedirectLogic'

const Confirmation = (props) => {
    let history = useHistory()
    const email = history.location.state.email
    const JWT = history.location.state.JWT
    const { Approve, err } = EmailRedirectLogic(props);

    useEffect(() => {
        Approve(JWT)// eslint-disable-next-line
    }, [])

    
    if (err) {
        return (
            <div className="div">
                <h1 style={{'color':'#ab1515'}}>Error</h1><br />
                <h3>An error has occurred. The ICAC Data System technical support team has been notified and will
                contact you as soon as possible.</h3>
            </div>
        )
    } else {
        return (
        <div className="div">
            <h1 style={{'color':'#4caf50'}}>Approved!</h1><br />
            <h3>Your request to approve <strong>{email}</strong> for email deconfliction was successful.
            <br />An email has been sent to {email} to confirm access
            to this system.</h3>
            <br />
            <br />
        </div>
        )
    }
}
export default Confirmation;