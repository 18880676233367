import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import api from "../../../utils/api";

const UserConfirmationLogic = (slug) => {
    const JWT = slug;
    const decodedJWT = jwt_decode(slug);
    let email = decodedJWT.email;
    console.log(email);

      //TODO try to find way to accomplish this without ignoring the linter
    useEffect(() => {
        Approve(JWT);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function Approve(JWT) {
        try {
            const response = await api.patch(`/core/limited/${JWT}/confirm/`)
            return response
        } catch (error) {
            console.error(error)
        }
    }
    return {}

}

export default UserConfirmationLogic;