import "../styles/RegistrationResponseStyles.css";

const RegistrationResponseUI = () => {
    return (
        <div class={"response-wrapper"}>
            <div class={"response-title"}>ICAC Partners Registration Form</div>
            <div class="response-innerContainer">
                <h2>Your registration has been successfully created. We have emailed you and your listed commander for confirmation and approval</h2>
            </div>
        </div>
    );
}

export default RegistrationResponseUI;