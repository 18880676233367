import React from "react";
import '../styles/EmailRedirectStyles.css'
import { Link } from "react-router-dom";
import {
    useParams
  } from "react-router-dom";
import jwt_decode from "jwt-decode";



const EmailRedirectUI = (props) => {

    const { slug } = useParams()
    const JWT = slug
    const decodedJWT = jwt_decode(slug)
    let email = decodedJWT.email

    
    return (
        <div className="redirectDiv">
            <h2>Click below to approve {email} for access to the email deconfliction system: </h2>
            <br /><Link to={{
                pathname: "/confirmation", 
                state: { email: email, JWT : JWT }
            }} type="button" className="redirectButton">Approve!</Link>
        </div>
    )
}
export default EmailRedirectUI;