import React, { useState } from 'react'
import { useHistory, Link } from "react-router-dom";
import './DisclaimerStyles.css'
import ReactModal from 'react-modal';

export default function Disclaimers() {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false)// eslint-disable-next-line
  const [disableCheck, setDisableCheck] = useState(true)

  const handleChange = () => {
      history.push("/register");
  }

  const handleClose = () => {
    setOpenModal(false)
    setDisableCheck(false)
  }
    
  return (
    <>
        <div className="div">
          <fieldset>
          <h2>ICAC Partners Registration</h2>
          <ul className="text-left">By accessing this system, I agree to the following: <br/><br/>
            <li className="text-left">I am the authorized user associated with this account.</li><br />
            <li className="text-left">I am currently in good standing with my Law Enforcement Agency and authorized to conduct ICAC investigations.</li><br />
            <li className="text-left">This system is intended to assist in case deconfliction.</li><br />
            <li className="text-left"> I will abide by all Federal, State, and local laws and regulations while using the ICAC Data System.</li><br />
            <li className="text-left">Failure to abide by this agreement could result in actions such as loss of access and/or legal actions.</li><br />
          </ul> 
            <label>
            <input type="checkbox" onChange={handleChange}/>
            By proceeding I agree to ALL the statements above and I have read and accept the <Link to="#" onClick={()=> setOpenModal(true)}>User Agreement</Link>.
          </label>
          </fieldset>           
        </div>
        <ReactModal
          isOpen={openModal}
        >
          <div>
            <h2 className="center">END USER LICENSE AGREEMENT AND ACCEPTABLE USE REQUIREMENTS</h2>
            <h4 className="center">Those using the ICAC Data System must agree and conform to this End User License Agreement and Acceptable Use Requirements. Additional guidelines and requirements will be added as the situation warrants. Periodic checking this End User License Agreement and Acceptable Use Requirements is recommended. Failure to follow any of these conditions may result in the suspension or revocation of my access.
            BY USING THIS TOOL YOU ACKNOWLEDGE AND AGREE TO:</h4>
            <ul className="li">
            <li>The ICAC Data System is for law enforcement personnel, persons employed by a law enforcement agency, or persons specifically employed to support law enforcement in investigations involving the online exploitation of children. </li>
            <br/><li>I am authorized by my agency to investigate or assist in the investigations of child exploitation crimes.</li>
            <br/><li>I acknowledge that all information available through the ICAC Data System is law enforcement sensitive. The ICAC Data System supports active law enforcement investigations; therefore no persons shall publicly demonstrate this system without the expressed permission of the ICAC Data System administrators. Users who expose data obtained through this system to non-law enforcement persons without expressed permission may have their account revoked.</li>
            <br/><li>No user of the ICAC Data System may sell or otherwise profit from the use of the system, the resulting leads, or related data without the expressed permission of the administrators of the ICAC Data System. Approved manuals, videos, or other training materials offered for distribution must be free to law enforcement. Additionally, all training materials developed for the ICAC Data System must be made available to all users of the system. This information is law enforcement sensitive.</li>
            <br/><li>I acknowledge that I must verify the information contained within the ICAC Data System, or other data bases associated with the ICAC Data System before taking any law enforcement action.</li>
            <br/><li>I will not attempt to circumvent the security or login features of the ICAC Data System in any way. I will not, nor will I solicit assistance to, reverse engineer or in any other way attempt to circumvent the intellectual property rights of those administering the ICAC Data System. Use of this system does not grant me ownership.</li>
            <br/><li>I acknowledge that any data I query or enter through the ICAC Data System is stored in a data base and thereby available to the other users who search the same information.</li>
            <br/><li>I agree to allow other ICAC Data System users to access the data I submit into the system. I understand that this information will remain available to all participating users even if I cease to use the system or withdraw in some other way from law enforcement.</li>
            <br/><li>I will only use the ICAC Data System for official law enforcement business.</li>
            <br/><li>I acknowledge that the ICAC Data System is not a criminal intelligence database or a case management system.</li>
            <br/><li>I acknowledge that information contained within the ICAC Data System does not imply guilt of the person related to the records or their involvement in criminal activity.</li>
            <br/><li>I acknowledge that this End User License Agreement and Acceptable Use Requirements are subject to change at any time and without notice.</li>
            </ul>
          </div>
          <button className="button" onClick={()=>handleClose()}>Agree</button>
        </ReactModal>
    </>
    )
}

