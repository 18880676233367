import "../success/styles/RegistrationResponseStyles.css";

const WLErrorResponse = () => {
    return (
    <div class={"response-wrapper"}>
    <div class={"response-title"}>Federal IDS Registration Form</div>
    <div class="response-innerContainer">
        <h2>We do not recognize the email domain you've used. Please <a href="https://www.icacdatasystem.com/#/report-issue">create an issue report</a> 
            or email us at support@icacdatasystem.com for assistance.</h2>
    </div>
    </div>
    );
}

export default WLErrorResponse;